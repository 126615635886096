<template>
<a-card :bordered="false">
    <div class="realtor">
        <!-- 查询表单 -->
        <a-form-model ref="searchForm" layout="horizontal" :label-col="{xs:8}" :wrapper-col="{xs:16}">
            <a-row :gutter="8">
                <a-col :md="8" :xl="6" :xxl="6" :sm="12">
                    <a-form-model-item label="分类名称">
                        <a-input v-model="searchData.name" placeholder="分类名称" allowClear></a-input>
                    </a-form-model-item>
                </a-col>
                <a-col :md="8" :xl="6" :xxl="6" :sm="12">
                    <a-form-model-item label="是否折扣&政策" :label-col="{xs:10}" :wrapper-col="{xs:14}">
                        <DictSelect field="RebatePolicyType" :value.sync="searchData.usedRebatePolicy" style="width: 100%" placeholder="是否折扣&政策"></DictSelect>
                    </a-form-model-item>
                </a-col>
                <a-col :md="8" :xl="6" :xxl="6" :sm="12">
                    <a-form-model-item label="是否使用积分">
                        <DictSelect field="IntegralType" :value.sync="searchData.usedIntegral" style="width: 100%" placeholder="是否使用积分"></DictSelect>
                    </a-form-model-item>
                </a-col>
                <a-col :md="8" :xl="6" :xxl="6" :sm="12">
                    <a-form-model-item label="是否返还积分">
                        <DictSelect field="IntegralType" :value.sync="searchData.returnIntegral" style="width: 100%" placeholder="是否返还积分"></DictSelect>
                    </a-form-model-item>
                </a-col>
                <a-col :xxl="6" :xl="5" :md="8" sm="2">
                    <a-form-model-item :label-col="{span: 0}" :wrapper-col="{span:24}">
                        <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
                        <a-button type="default" @click="reset()" icon="sync">重置</a-button>
                    </a-form-model-item>
                </a-col>
            </a-row>
        </a-form-model>
        <div>
<!--             <a-button type="primary" @click="toHandler('add')">新增</a-button>-->
            <a-button type="primary" v-if="isEdit && selectedRows.length == 0 || (selectedRows.length <= 1 && selectedRows[0].level == 1)" @click="toHandler('edit')">修改</a-button>
            <!-- <a-button type="primary" @click="toHandler('del')">删除</a-button> -->
        </div>
        <!-- 数据表格 -->
        <a-table :loading="tableLoading" :row-selection="rowSelection" :rowKey="record => record.id"
            :columns="columns" :data-source="tableData" bordered :pagination="page"
            @change="changeTable" :customRow="changeTableRow" :rowClassName="rowClassName">
            <span slot="imgUrl" slot-scope="item">
                <ZoomMedia style="height: 20px" :value="item"></ZoomMedia>
            </span>
            <span slot="usedRebatePolicy" slot-scope="text">
                <a-tag v-if="text == 1">折扣</a-tag>
                <a-tag v-if="text == 2">政策</a-tag>
                <a-tag v-if="text == 3">折扣&政策 2选1</a-tag>
                <a-tag v-if="text == 4">折扣&政策</a-tag>
            </span>
            <span slot="usedIntegral" slot-scope="text">
                <span v-if="text"><a-icon type="check-circle" style="color: green;" theme="filled" /></span>
                <span v-else><a-icon type="close-circle" style="color: red;" theme="filled" /> </span>
            </span>
            <span slot="returnIntegral" slot-scope="text">
                <span v-if="text"><a-icon type="check-circle" style="color: green;" theme="filled" /></span>
                <span v-else><a-icon type="close-circle" style="color: red;" theme="filled" /> </span>
            </span>
        </a-table>
        <!-- 编辑表单 -->
        <CategoryRebatePolicy-edit-modal ref="CategoryRebatePolicyEditModal" @reload="getData"></CategoryRebatePolicy-edit-modal>
    </div>
</a-card>

</template>

<script>
import { columns } from './components/colums.js'
import CategoryRebatePolicyEditModal from './components/CategoryRebatePolicyEditModal.vue'
import { delCategoryRebatePolicy, listCategoryRebatePolicy, selectByIdCategoryRebatePolicy } from './api/CategoryRebatePolicyApi'
import {checkPermission} from "@/utils/permissions";

export default {
    name: 'categoryRebatePolicy',
    components: {
        CategoryRebatePolicyEditModal
    },
    data() {
        return {
            columns: columns,
            tableData: [],
            searchData: {}, // 搜索条件
            scroll:{ y: 600 },
            tableLoading: false, //表格loading
            page: {
                current: 1,
                pageSize: 10,
                total: 0,
                showSizeChanger: true,
                showTotal: () => `共${this.page.total}条`,
            },
            isEdit: checkPermission('category:rebate:policy:edit'),
            selectedRowKeys: [],
            selectedRows: []
        }
    },
    computed: {
        rowSelection() {
            const _this = this
            return {
                fixed: true,
                type: 'radio', //"radio"
                selectedRowKeys: this.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    _this.selectedRowKeys = selectedRowKeys
                    _this.selectedRows = selectedRows
                }
            }
        }
    },
    methods:{
        // 隔行换色
        rowClassName(record,index) {
            let className = "light-row"
            if (index % 2 === 1) className = "dark-row"
            return className;
        },
        /**
         * 获取表格数据
         */
        getData() {
            this.tableLoading = true
            listCategoryRebatePolicy ({
                pageNumber: this.page.current,
                pageSize: this.page.pageSize,
                ...this.searchData
            }).then(res => {
                if (res.code === 200) {
                    // const { total, records } = res.body
                    this.page.total = res.body ? res.body.length : 0
                    this.tableData = res.body
                }
            }).finally(() => this.tableLoading = false)
        },
        /**
         * 筛选查询
         */
        getDataFilter() {
            this.page.current = 1;
            this.getData()
        },
        /**
         * 重置
         */
        reset() {
            this.searchData = {}
            this.page.current = 1;
            this.page.pageSize = 10;
            // this.page = Object.assign({}, this.page)
            this.getData()
        },
        /**
         * 更改分页
         */
        changeTable(pagination) {
            this.page = pagination
            this.getData()
        },
        /**
         * 点击行选中
         */
        changeTableRow(record) {
            return {
                on: {
                    click: e => {
                        console.log(e)
                        if (this.selectedRowKeys[0] === record.id) {
                            this.selectedRowKeys = []
                            this.selectedRows = []
                        } else {
                            this.selectedRowKeys = [record.id]
                            this.selectedRows = [record]
                        }
                    }
                }
            }
        },
        toHandler(name) {
            const _this = this
            if (name === 'add') {
                _this.$refs.DemandEditModal.setRowData({}, 'add')
                return
            }
            if (_this.selectedRows.length === 0) {
                _this.$notification.warning({
                    message: '请选择一条记录'
                })
                return
            }
            switch (name) {
                case 'edit':
                    _this.$refs.CategoryRebatePolicyEditModal.setRowData(_this.selectedRows[0], 'edit')
                    break
                case 'del':
                    _this.$confirm({
                        title: '删除警告',
                        content: '确认要删除此条记录吗?',
                        okText: '确认',
                        cancelText: '取消',
                        async onOk() {
                            const res = await delCategoryRebatePolicy(_this.selectedRows[0].id)
                            if (res.code === 200) {
                                _this.$notification.success({ message: res.message })
                                this.getData()
                            } else {
                                _this.$notification.error({ message: res.message })
                            }
                        },
                        onCancel() {}
                    })
                    break
            }
        },
    },
    created() {
        this.getData()
    }
}
</script>

<style lang="less" scoped>
    .ant-btn {
        margin-right: 8px;
        margin-bottom: 12px;
    }
    ::v-deep .ant-table-tbody > .light-row {
        background: #FFF!important;;
    }
    ::v-deep .ant-table-tbody > .dark-row {
        background: #fafafa!important;
    }
</style>
